import React from 'react';
import { IOrderProduct } from '../../redux/orders/OrderTypes';

type Props = {
  orderItems: IOrderProduct[];
};

const getExtraType = (oItem: any) => {
  console.log(oItem);
  const ePrice = oItem.extraPrice / oItem.quantity;

  if (ePrice === 0) {
    return '';
  }
  if (ePrice === 550 || ePrice === 1470) {
    return 'Laktózmentes sajt';
  }
  if (ePrice === 390) {
    return 'Teljes kiőrlésű tészta';
  }
  return 'Laktózmentes sajt + Teljes kiőrlésű tészta';
};

const getExtraItemsPrice = (oItem: IOrderProduct) => {
  const price = oItem?.itemContent
    .filter((c) => c?.isAddition)
    .reduce((acc, cur) => cur?.item?.price! + acc, 0);

  return price === 0 ? 0 : price;
};

export const OrderItemsTable = (props: Props) => {
  return (
    <>
      {props.orderItems.map((orderItem) => (
        <div key={orderItem.id}>
          {orderItem.quantity} {'db '}
          <b>
            {orderItem.item?.name} ({orderItem.item?.parentCategory?.name})
          </b>
          <br />
          <b>
            {'(termék ára: ' +
              (orderItem.price - getExtraItemsPrice(orderItem)) +
              ' Ft)'}
          </b>
          {orderItem.item?.isRedemption && (
            <b> + Visszaváltási díj: {50 * orderItem.quantity} Ft</b>
          )}
          <p>Alap feltétek:</p>
          {orderItem.itemContent
            .filter((c) => !c.isAddition)
            .map((c) => (
              <ul
                key={c.id}
                style={{ listStyleType: 'none', padding: 0, margin: 0 }}
              >
                <li>
                  {c.quantity} db - {c.item?.name}
                </li>
              </ul>
            ))}
          <p style={{ marginTop: '15px' }}>
            Extra feltétek: <b> {getExtraItemsPrice(orderItem)} Ft</b>
          </p>
          <span>{getExtraType(orderItem)}</span>
          {orderItem.itemContent
            .filter((c) => c.isAddition)
            .map((c) => (
              <ul
                key={c.id}
                style={{ listStyleType: 'none', padding: 0, margin: 0 }}
              >
                <li>
                  {c?.quantity} db - {c?.item?.name}
                </li>
              </ul>
            ))}
          <hr />
        </div>
      ))}
    </>
  );
};
