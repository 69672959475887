import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Button,
  Input,
  Card,
  CardBody,
  CardText,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { ProductActions } from "../../redux/products/ProductActions";
import { IProduct, ProductActionType } from "../../redux/products/ProductTypes";
import { RootState } from "../../redux/store";
import { ImageHandler } from "../ImageHandler";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CheckMark = () => (
  <span>
    <FontAwesomeIcon size="2x" icon={faCheck} color="green" />
  </span>
);
const Times = () => (
  <span>
    <FontAwesomeIcon size="2x" icon={faTimes} color="red" />
  </span>
);

type Props = {
  product: IProduct;
  productActions?: ProductActionType;
  isTableView: Boolean;
};
const ProductItemView = (props: Props) => {
  //const { product } = props;
  const [product, setProduct] = useState<IProduct>(props.product);
  const [isProdAvailable, setIsProdAvailable] = useState<boolean>(
    props.product.isAvailable
  );
  const { path } = useRouteMatch();
  const history = useHistory();

  async function deleteProductClicked() {
    await props.productActions?.deleteProduct(product);
    history.push(`${path}/`);
    alert("Termék kitörölve!");
    window.location.reload();
  }

  function updateProductClicked() {
    history.push(`${path}/updateproduct/${props.product.id}`);
  }

  // Change price
  const [isEditing, setEditing] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const handleItemChange = () => {
    if (!product.name || !product.price) return;
    setEditing(false);
    setIsLoading(true);
    addProduct().finally(() => setIsLoading(false));
  };

  const addProduct = async () => {
    props.productActions?.modifyProduct(product, undefined);
  };

  const ProductCard = () => {
    return (
      <Card style={{ backgroundColor: !product.isAvailable ? "#bbb" : "" }}>
        <CardBody>
          <Row>
            <Col lg="6">
              <h6>Azonosító: {product.id ?? "-"}</h6>
              <div>
                <ImageHandler filePath={product.picture?.filePath} />
              </div>
              <b>Feltétek:</b>
              <ul>
                {product.contents?.map((it) =>
                  it.itemId ? (
                    <li key={it.itemId}>
                      {it.quantity}db {it.item?.name} (termék)
                    </li>
                  ) : (
                    <li key={it.categoryId}>
                      {it.quantity}db {it.category?.name} (kategória)
                    </li>
                  )
                )}
              </ul>
              <b>Extra feltétek:</b>
              <ul>
                {product.additionalItems?.map((it) =>
                  it.itemId ? (
                    <li key={it.itemId}>
                      {it.maxQuantity}db {it.item?.name} (termék)
                    </li>
                  ) : (
                    <li key={it.categoryId}>
                      {it.maxQuantity}db {it.category?.name} (kategória)
                    </li>
                  )
                )}
              </ul>
            </Col>
            <Col lg="6" className="d-flex flex-column">
              <h5>Név: {product.name ?? "-"}</h5>
              <CardText onClick={() => setEditing(true)}>
                <b>Ár:</b> {product.price ?? "-"} Ft
              </CardText>
              <CardText>
                <b>Áfakulcs:</b> {product.vatRate ?? "-"} %
              </CardText>
              <CardText>
                <b>Hűségpont jóváírás:</b> {product.loyaltyPointsRatio ?? "-"} %
              </CardText>
              <CardText>
                <b>Külön számlázott?</b>{" "}
                {product.shouldBillItems ? "igen" : "nem"}
              </CardText>
              <CardText>
                <b>Leírás:</b> {product.description ?? "-"}
              </CardText>
              <CardText>
                <b>Elérhető-e a kínálatban:</b>{" "}
                {product.isAvailable ? <CheckMark /> : <Times />}
              </CardText>
              <Row style={{ marginTop: "auto" }}>
                <Col>
                  <Button color="danger" onClick={deleteProductClicked}>
                    Törlés
                  </Button>
                </Col>
                <Col>
                  <Button color="info" onClick={updateProductClicked}>
                    Szerkesztés
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      {!props.isTableView ? (
        <ProductCard />
      ) : (
        <tr>
          {isEditing ? (
            <>
              <td onClick={handleItemChange}>
                {isLoading ? <Spinner /> : <CheckMark />}
              </td>
              <td>
                <Input
                  key={product.id}
                  value={product.name}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      name: e.target.value,
                    })
                  }
                  required
                  min={0}
                ></Input>
              </td>
              <td>
                <Input
                  type="number"
                  value={product.price}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      price: parseInt(e.target.value),
                    })
                  }
                  required
                  min={0}
                ></Input>
              </td>
              <td>
                <Input
                  type="number"
                  value={product.vatRate}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      vatRate: parseInt(e.target.value),
                    })
                  }
                  required
                  min={0}
                ></Input>
              </td>
              <td>
                <Input
                  type="number"
                  value={product.loyaltyPointsRatio}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      loyaltyPointsRatio: parseInt(e.target.value),
                    })
                  }
                  required
                  min={0}
                ></Input>
              </td>
              <td
                onClick={() => {
                  setIsProdAvailable(!isProdAvailable);
                  setProduct({ ...product, isAvailable: !isProdAvailable });
                }}
              >
                {isProdAvailable ? <CheckMark /> : <Times />}
              </td>
            </>
          ) : (
            <>
              <td></td>
              <td onClick={() => setEditing(true)}>{product.name ?? "-"}</td>
              <td onClick={() => setEditing(true)}>
                {product.price ?? "-"} Ft
              </td>
              <td onClick={() => setEditing(true)}>
                {product.vatRate ?? "-"} %
              </td>
              <td onClick={() => setEditing(true)}>
                {product.loyaltyPointsRatio ?? "-"} %
              </td>
              <td onClick={() => setEditing(true)}>
                {product.isAvailable ? <CheckMark /> : <Times />}
              </td>
            </>
          )}
          <td>{product.shouldBillItems ? <CheckMark /> : <Times />}</td>
          <td>{product.billingPrice ? product.billingPrice : "0"} Ft</td>
          <td>
            <Button color="info" onClick={updateProductClicked}>
              Szerkesztés
            </Button>
          </td>
          <td>
            <Button color="danger" onClick={deleteProductClicked}>
              Törlés
            </Button>
          </td>
        </tr>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allToppings: state.productReducer.products,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    productActions: ProductActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ProductItemView);
