import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Col,
  Input,
  Row,
} from "reactstrap";
import { IOrder, OrderActionType } from "../../redux/orders/OrderTypes";
import {
  convertPaymentToString,
  getInitialColor,
  getPaymentStatus,
} from "./OrderItemFunctions";
import { OrderItemsTable } from "./OrderItemsTable";
import useClipboard from "react-use-clipboard";

type Props = {
  order: IOrder;
  orderActions?: OrderActionType;
  archive?: boolean;
};
const OrderItemView = (props: Props) => {
  const { order } = props;
  let history = useHistory();
  const { path } = useRouteMatch();
  const audio = new Audio("/notification.mp3");
  const [copyName, setCopyName] = useClipboard(order.name, {
    successDuration: 2000,
  });
  const [copyPhone, setCopyPhone] = useClipboard(order.phone || "", {
    successDuration: 2000,
  });
  const [copyPayment, setCopyPayment] = useClipboard(order.payment, {
    successDuration: 2000,
  });
  const [copyComment, setCopyComment] = useClipboard(order.comment || "", {
    successDuration: 2000,
  });

  const getDiffInClockString = useCallback(() => {
    const hour = moment().diff(order.createdAt, "hours");
    const min = moment().diff(order.createdAt, "minutes") % 60;
    const sec = moment().diff(order.createdAt, "seconds") % 60;
    const hourStr = hour !== 0 ? `${hour} óra ` : "";
    const minStr = min !== 0 ? `${min} perc ` : "";
    const secStr = sec !== 0 ? `${sec} másodperc` : "";
    return hourStr + minStr + secStr;
  }, [order.createdAt]);

  const [time, setTime] = useState<string>(getDiffInClockString());

  const [cardColor, setcardColor] = useState<string>(
    getInitialColor(order, props.archive)
  );
  const firstRun = useRef(true);
  const shippingTime = useRef(0);
  useEffect(() => {
    if (path === "/dashboard/archive") return;
    const timeUpdateInterval = setInterval(() => {
      setTime(getDiffInClockString());
    }, 1000);
    let soundAndColorUpdateInterval: NodeJS.Timeout;
    if (props.order.phase === "NEW_ORDER") {
      soundAndColorUpdateInterval = setInterval(() => {
        const diff = moment().diff(order.createdAt, "minute", true);
        if (diff >= 1 && diff < 3) {
          setcardColor("warning");
        } else if (diff >= 3) {
          setcardColor("danger");
          if (!firstRun.current) audio.play();
          clearInterval(soundAndColorUpdateInterval);
        }
        firstRun.current = false;
      }, 1000);
    }
    return () => {
      clearInterval(timeUpdateInterval);
      clearInterval(soundAndColorUpdateInterval);
    };
  }, [getDiffInClockString, order.createdAt, props.order.phase]);

  const isInverse = () => cardColor === "danger" || cardColor === "info";
  const preparingAction = async () => {
    await props.orderActions?.emitOrderStartedPreparing(
      props.order.id!,
      shippingTime.current
    );
    history.push(`${path}/${props.order.id}`);
  };
  const shippingDate = new Date(order.shippingDate);

  const changeShippingTime = (minutes: number) => {
    shippingTime.current = minutes;
  };

  if (!order) {
    return <></>;
  }

  const calcAdd = () => {
    let add = 0;
    order.orderItems.forEach((item) => {
      // @ts-ignore
      if (item.item.isRedemption) {
        add += item.quantity * 50;
      }
    });

    return add;
  };

  return (
    <Card color={cardColor} inverse={isInverse()}>
      <Row>
        <Col>
          <CardHeader>
            <i>Rendelés azonosító:</i> <u>{order.id}</u>
          </CardHeader>
        </Col>
        <Col>
          <CardHeader>
            <i>Étterem:</i> <u>{order.city.name}</u>
          </CardHeader>
        </Col>
      </Row>

      <CardBody>
        <CardText onClick={setCopyName}>
          <b>Megrendelő neve: </b>
          <span style={{ color: "blue" }}>{order.name} </span>{" "}
          {copyName ? <b>Kimásolva</b> : ""}
        </CardText>

        <CardText>
          <b>Mikorra kérte: </b>
          <span style={{ color: "red" }}>
            {moment(shippingDate).format("MMMM Do - HH:mm")}
          </span>
        </CardText>
        <CardText>
          <b>Cím: </b>
          {order.address}
        </CardText>
        <CardText onClick={setCopyPayment}>
          <b>Fizetés módja: </b>
          <span style={{ color: "blue" }}>
            {convertPaymentToString(order.payment!) +
              " (" +
              getPaymentStatus(order.paymentStatus) +
              ")"}{" "}
          </span>{" "}
          {copyPayment ? <b>Kimásolva</b> : ""}
        </CardText>
        {order.coupon && (
          <>
            <CardText>
              <b>Kupon nélküli ár: </b>
              {order.originalPrice} Ft
            </CardText>
            <CardText>
              <b>Felhasznált kupon: </b>
              {order.coupon?.name} ({order.coupon?.codeToUse})
            </CardText>
          </>
        )}
        <CardText>
          <b>Felhasznált hűségpont: </b>
          {order.usedLoyaltyPoints ?? 0} ft
        </CardText>
        <CardText>
          <b>Ár szállítással együtt: </b>
          {order.finalPrice} ft
        </CardText>
        <CardText>
          <b>Szállítási díj: </b>
          {order.shippingPrice} ft
        </CardText>
        <CardText onClick={setCopyPhone}>
          <b>Telefonszám: </b>
          <span style={{ color: "blue" }}>
            {order.phone?.replace("+36", "06")}{" "}
          </span>{" "}
          {copyPhone ? <b>Kimásolva</b> : ""}
        </CardText>
        {order.comment && (
          <CardText
            style={{ width: "320px", wordBreak: "break-all" }}
            onClick={setCopyComment}
          >
            <b>Megjegyzés: </b>
            <>
              <span style={{ color: "blue" }}>
                {order.comment}
                <span></span>
              </span>
            </>
            {copyComment ? <b>Kimásolva</b> : ""}
          </CardText>
        )}
        {order.preparedByWorker && (
          <CardText>
            <b>Készíti: </b>
            {order.preparedByWorker?.name} (id:{order.preparedByWorkerId})
          </CardText>
        )}

        <hr style={{ backgroundColor: "white" }} />
        <CardText>
          <b>Termékek: </b>
        </CardText>
        <OrderItemsTable orderItems={order.orderItems} />

        {/* <OrderItemsTable isInverse={isInverse()} orderItems={order.orderItems} /> */}
      </CardBody>

      <CardFooter>
        <b>Beérkezett: {moment(order.createdAt).format("MMMM Do HH:mm:ss")}</b>
      </CardFooter>
      <CardFooter>
        <b> Eltelt idő: {time}</b>
      </CardFooter>
      {!props.archive && order.phase === "NEW_ORDER" && (
        <>
          <CardFooter>
            <h5>Kiszállítási idő:</h5>
            <Row>
              <Col md={4}>
                <Button
                  onClick={() => changeShippingTime(45)}
                  active={shippingTime.current === 45}
                >
                  45 perc
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  onClick={() => changeShippingTime(60)}
                  active={shippingTime.current === 60}
                >
                  60 perc
                </Button>
              </Col>
              <Col md={4}>
                <Input
                  type="number"
                  onChange={(e) => changeShippingTime(parseInt(e.target.value))}
                  placeholder={"Egyedi"}
                />
              </Col>
            </Row>
          </CardFooter>
          <CardFooter>
            <Button onClick={preparingAction} disabled={!shippingTime.current}>
              Elkészítés ({shippingTime.current} perc)
            </Button>
          </CardFooter>
        </>
      )}
      {!props.archive && order.phase !== "NEW_ORDER" && (
        <CardFooter>
          <Button onClick={() => history.goBack()}>Vissza</Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default OrderItemView;
